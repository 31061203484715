import React from 'react'
import './base.css'
import Container from './container'
import Navigation from './navigation'

class Template extends React.Component {
  render() {
    const { children, pages, page, route, locale, addresses} = this.props;
    return (
      <Container>
        <Navigation pages={pages} page={page} route={route} locale={locale} addresses={addresses} />
        {children}
      </Container>
    )
  }
}

export default Template
