import React from 'react'
import { Link } from 'gatsby'
import styles from './navigation.module.css'
import logo from '../images/de-graaff-icon.png'
import svgIt from '../images/it.svg'
import svgEn from '../images/gb.svg'
import cx from 'classnames'

export default ({ pages, page, route, locale, addresses }) => {
  return (
    <nav className={styles.navigation} role="navigation">
      <Link className={styles.navigationLogo} to={'/' + (locale ? "?language=" + locale : "")}>
        <img alt="" src={logo} className={styles.logo} />
        <div className={styles.title}>
          <h4>Centro Odontoiatrico</h4>
          <h4>De Graaff</h4>
        </div>
        <div className={styles.navigationAddressesHolder}>
          <div className={styles.navigationAddresses}>
            {addresses && addresses.map(({ title }) => (
              <h6 key={title}>{title}</h6>
            ))}
          </div>
        </div>
      </Link>

      <div className={styles.navigationHam}>
        <span>=</span>
      </div>
      <div className={styles.navigationContainer}>
        <ul className={styles.navigationList}>
          { pages.map(({ name, navName, showInMenu, subPages }, index) => { 
            return page && name && showInMenu ? 
              <li key={name} className={cx(styles.navigationItem, subPages && subPages.length > 0 ? styles.navigationItemWithSubPages : "")} data-active={page.navName === navName}>
                <Link to={index === 0 ? '/' + (locale ? "?language=" + locale : "") : '/#' + navName + (locale ? "?language=" + locale : "")}>{name}</Link>
                { subPages ?
                  <ul className={styles.navigationSubPages}>
                    { subPages.map(({ name, navName }) => (
                      <li key={name} className={styles.subNavigationItem} data-active={page.navName === navName}>
                        <Link to={'/#' + navName + (locale ? "?language=" + locale : "")}>{name}</Link>
                      </li>
                    ))}
                  </ul>
                : null}
              </li>
            : null
          })}
        </ul>
        
        <li className={styles.navigationLocales}>
          <Link to={'/#' + route + "?language=it"}><img src={svgIt} alt="Italian" /></Link>
          <Link to={'/#' + route + "?language=en"}><img src={svgEn} alt="English" /></Link>
        </li>
      </div>
    </nav>
  )
}
