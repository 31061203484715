import React from 'react'
import styles from './header.module.css'
import Img from 'gatsby-image'
import BackgroundSlider from 'react-background-slider'
import { documentToHtmlString } from '@contentful/rich-text-html-renderer'
import cx from 'classnames'
import AppSubPages from '../apps/AppSubPages'
import phoneSvg from '../images/phone.svg'
import emailSvg from '../images/email.svg'

export default ({ page, contact, locale }) => {
  const imageStrings = [];
  page && page.images && page.images.map((image) => {
    imageStrings.push(image.sizes.src)
  });
  return page ? (
    <header className={cx(styles.header, !page.images ? styles.headerContentNoImage : "", !!page.description || !!page.app ? styles.small : "")}>
      <div className={styles.headerWrapper}>
        {page && page.images && page.images.length > 1 ?
          <div className={cx(styles.headerImage, styles.headerBackgroundSlider)}><BackgroundSlider images={imageStrings} transition={.5} duration={4} /></div>
        : page && page.images && page.images.length === 1 ?
          <Img className={styles.headerImage} alt="" fluid={page.images[0].sizes} />
        : null}
        {page.announcement ?
          <span className={styles.headerAnnouncement}>
            {page.announcement}
          </span>
        : null}
      </div>
      <div className={styles.headerContainer}>
        <section className={styles.headerContent}>
          <div className={styles.headerTitleHolder}>
            {page.icon && page.icon.file ?
              <div className={styles.iconHolder}>
                <img className={styles.icon} src={"https:" + page.icon.file.url} alt={page.name} /> 
              </div>
            : null}
            <div className={styles.headerTitle} dangerouslySetInnerHTML={{__html: documentToHtmlString(page.title.json) }} />
          </div>
          {page.subTitle && <div className={styles.headerSubTitle} dangerouslySetInnerHTML={{__html: documentToHtmlString(page.subTitle.json) }} />}
          { page.subPages ? 
            <AppSubPages className={styles.subPages} subPages={page.subPages} locale={locale} />
            : null
          }
          {page.description ?
            <div className={styles.headerDescription}>
              <div className={styles.headerDescriptionTextHolder}>
                <div className={styles.headerDescriptionText} dangerouslySetInnerHTML={{__html: documentToHtmlString(page.description.json) }} />
              </div>
              <div className={styles.headerDescriptionContact}>
                <h3>{contact.headerTitle}</h3>
                <div className={styles.headerDescriptionContactDetails}>
                  {contact.addresses.map(({title, tel}) => (
                    <section key={title}>
                      <span className={styles.contactOption}><img className={styles.contactIcon} src={phoneSvg} alt="phone" /><span>{`Studio ${title}: `}</span></span> <a href={`tel:${tel}`}>{tel}</a>
                    </section>
                  ))}
                  <span className={styles.contactOption}><img className={styles.contactIcon} src={emailSvg} alt="email" /><a href={"mailto:" + contact.addresses[0].email + "?subject=Centro De Graaff" + (locale === 'en' ? " question" : " domanda")}>{contact.addresses[0].email}</a></span>
                </div>
              </div>
            </div>
          : null}
        </section>
        {page.headerWidget1 || page.headerWidget2 || page.headerWidget3 ?
          <section className={styles.headerWidgets}>
            <div className={styles.headerWidget} dangerouslySetInnerHTML={{__html: documentToHtmlString(page.headerWidget1.json) }} />
            <div className={styles.headerWidget} dangerouslySetInnerHTML={{__html: documentToHtmlString(page.headerWidget2.json) }} />
            <div className={styles.headerWidget} dangerouslySetInnerHTML={{__html: documentToHtmlString(page.headerWidget3.json) }} />
          </section>
        : null}
        {page.announcement ?
          <span className={cx(styles.headerAnnouncement, styles.mobile)}>
            {page.announcement}
          </span>
        : null}
      </div>
    </header>
  ) : null
}
