import React from 'react'
import { Link } from 'gatsby'
import styles from './footer.module.css'
import cx from 'classnames'

export default ({ contact, page, locale }) => {
  return (
    <footer className={cx(styles.footer, page && (page.headerWidget1 || page.headerWidget1 || page.headerWidget3) ? styles.footerWithoutBlueBar : "")}>
      <article className={styles.contact}>
        <h4 className={styles.contactTitle}>{contact.title}</h4>
        {contact.addresses.map(({title, street, tel, email, location}) => (
          <section className={styles.contactAddress} key={title}>
            <h4 className={styles.contactTitle}>
              {title}
            </h4>
            <div>
              {location && location.lat && location.lon ?
                <p><a target="_blank" href={`https://maps.google.com/?q=${location.lat} ${location.lon}`}>{street}</a></p>
              : <p>{street}</p>}
              <p>tel: <a href={`tel:${tel}`}>{tel}</a></p>
              <a href={"mailto:" + email + "?subject=Centro De Graaff" + (locale === 'en' ? " question" : " domanda")}>{email}</a>
            </div>
          </section>
        ))}
        <section className={styles.contactInfo}>
          <Link to={"/#Studio" + (locale ? "?language=" + locale : "")} className={styles.contactTitle}>
            Studio
          </Link>
          <Link to={"/#Servizi" + (locale ? "?language=" + locale : "")} className={styles.contactTitle}>
            {locale === "it" ? "Servizi" : "Services"}
          </Link>
          <Link to={"/#Tecnologia" + (locale ? "?language=" + locale : "")} className={styles.contactTitle}>
            {locale === "it" ? "Tecnologia" : "Technology"}
          </Link>
        </section>
      </article>
      <article className={styles.copyright}>
        <p>
          © 2020 Centro Odontoiatrico De Graaff
        </p>
      </article>
    </footer>
  )
}
