import React from 'react'
import { Link } from 'gatsby'
import styles from './selections.module.css'
import cx from 'classnames'

export default ({ subPages, locale, className }) => {
  return (
    <section className={cx(className, styles.wrapper)}>
			<ul className={styles.selections}>
				{ subPages.map(({ name, navName, icon }, index) => (
					<Link key={index} className={styles.selection} to={'/#' + navName + (locale ? "?language=" + locale : "")}>
						{icon && 
							<div className={styles.iconHolder}>
								<img className={styles.icon} src={"https:" + icon.file.url} alt={name} /> 
							</div>
						}
						<div className={styles.content}>
							<h5 className={styles.name}>{name}</h5>
						</div>
					</Link>
				))}
			</ul>
    </section>
  )
}
